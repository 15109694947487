.eventCardBackground {
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(30px);
}


.eventCardQuestionBackground {
  /* background: url('../../assets/images/common/maybe-event-bg.png') lightgray 50% / cover no-repeat; */
  background-image: url('assets/images/common/maybe-event-bg.png') !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.eventCardBlockedBackgroundWeek {
  background: repeating-linear-gradient(-45deg,
      /* Angle of the lines */
      lightgray,
      /* Line color */
      red 1px,
      /* Thickness of the red line */
      transparent 1px,
      /* Space after the red line */
      transparent 10px
      /* Total space before the next red line */
    );
}



/* left border */
.calendar-card-left-border {
  border-left: 3px solid #C94A2E !important;
}