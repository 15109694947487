@font-face {
  font-family: 'Westmount';
  src: url('Westmount-ExtraLight.eot');
  src:
    local('Westmount-ExtraLight'),
    url('Westmount-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Westmount-ExtraLight.woff2') format('woff2'),
    url('Westmount-ExtraLight.woff') format('woff'),
    url('Westmount-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

.Westmount {
  font-family: 'Westmount', sans-serif;
}