.tabsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.tabsContainer > * {
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #cecece46;
  width: 100%;
}

.active {
  border-bottom: 1px solid #cececeaa;
}
