@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --color-primary: #4B3BFF;
  --color-primary-dark: #3929CC;
  --color-secondary: #007AFF;
  --color-dark: #0A0118;
  --color-white: #FFFFFF;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--color-dark);
  color: var(--color-white);
}

.bg-dark {
  background-color: var(--color-dark);
}

.bg-dark-transparent {
  background-color: rgba(10, 1, 24, 0.8);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-primary-dark {
  background-color: var(--color-primary-dark);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

.text-primary {
  color: var(--color-primary);
}

.border-white-10 {
  border-color: rgba(255, 255, 255, 0.1);
}

.shadow-primary-25 {
  box-shadow: 0 4px 6px -1px rgba(75, 59, 255, 0.25);
}

.shadow-secondary-20 {
  box-shadow: 0 4px 6px -1px rgba(0, 122, 255, 0.2);
}
